<template>
  <div class="newTweet">
    <div class="box">
      <div class="left">
        <div class="title">1.智能推文功能</div>

        <div class="text">
          根据鼠标选中、输入文本、光标位置上下文，智能推荐续写、关联内容。双击插入、复制进编辑框，大幅降低创作难度、激发创作灵感。
        </div>

        <div class="btn_box">
          <div
            class="btn_item"
            @click="
              $router.push({
                name: 'DownloadGuide',
                params: {
                  tabIndex: 0,
                },
              })
            "
          >
            <img :src="require('assets/images/newTweet/icon1.png')" alt="" />
            下载使用“成语极速版”
          </div>
          <div
            class="btn_item fff"
            @click="
              $router.push({
                name: 'DownloadGuide',
                params: {
                  tabIndex: 3,
                },
              })
            "
          >
            <img :src="require('assets/images/newTweet/icon2.png')" alt="" />
            下载使用“成语Office/WPS版”
          </div>
        </div>

        <div class="title">2.智能推文按钮</div>

        <div class="text">
          <span>2.1</span>
          成语极速版主界面的按钮
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc1.png')"
          alt=""
          width="435px"
        />

        <div class="text">
          <span>2.2</span>
          成语极速版文本框内的按钮
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc2.png')"
          alt=""
          width="414px"
        />

        <div class="text">
          <span>2.3</span>
          成语Office/WPS版AI菜单栏的按钮
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc3.png')"
          alt=""
          width="683px"
        />

        <div class="title">3.成语极速版·快速上手智能推文</div>

        <div class="text">
          适用场景：适合Office、WPS、微信、QQ、有道云笔记、钉钉、浏览器等各类写作、聊天、搜索场景。
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc4.png')"
          alt=""
          width="417px"
        />

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc12.png')"
          alt=""
          width="442px"
        />

        <div class="text">
          <span> 第一步，</span>
          鼠标选中文本或者输入文本
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc6.png')"
          alt=""
          width="367px"
        />

        <div class="text" style="text-align: center">（鼠标选中文本）</div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bg.png')"
          alt=""
          width="414px"
        />

        <div class="text" style="text-align: center">
          （直接在文本框中输入文本）
        </div>

        <div class="text">
          <span> 第二步，</span>
          点击智能推文按钮
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc1.png')"
          alt=""
          width="435px"
        />

        <div class="text" style="text-align: center">
          （基于外部内容，点击主界面按钮）
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc2.png')"
          alt=""
          width="414px"
        />

        <div class="text" style="text-align: center">
          （基于文本框内容，点击框内按钮）
        </div>

        <div class="text">
          <span> 第三步，</span>
          推荐出续写、关联内容，查阅选择，双击点击、一键复制进编辑框
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc7.png')"
          alt=""
          width="528px"
        />

        <div class="title">4.成语Office/WPS版·快速上手智能推文</div>

        <div class="text">适用场景：Office、WPS写作场景。</div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc8.png')"
          alt=""
          width="683px"
        />

        <div class="text" style="text-align: center">
          （Office一级菜单栏下，点开AI功能）
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc9.png')"
          alt=""
          width="683px"
        />

        <div class="text" style="text-align: center">
          （Office右侧弹窗，即成语功能界面）
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc10.png')"
          alt=""
          width="683px"
        />

        <div class="text" style="text-align: center">
          （WPS一级菜单栏下，点开AI功能）
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc11.png')"
          alt=""
          width="683px"
        />

        <div class="text" style="text-align: center">
          （WPS右侧弹窗，即成语功能界面）
        </div>

        <div class="text">
          <span> 第一步，</span>
          鼠标选中文本，或者放在待需要推荐的文本末尾
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc5.png')"
          alt=""
          width="367px"
        />

        <div class="text" style="text-align: center">
          （鼠标选中需要续写的文本）
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc13.png')"
          alt=""
          width="378px"
        />

        <div class="text" style="text-align: center">
          （鼠标点击续写的位置）
        </div>

        <div class="text">
          <span>第二步，</span>
          点击智能推文按钮
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc14.png')"
          alt=""
          width="683px"
        />

        <div class="text">
          <span> 第三步，</span>
          推荐出续写、关联内容，查阅选择，双击点击、一键复制进编辑框
        </div>

        <img
          class="bgc_img"
          :src="require('assets/images/newTweet/bgc15.png')"
          alt=""
          width="683px"
        />

        <div class="title">5.如何选择语境算法引擎</div>

        <div class="text">
          算法决定内容的精确度、专业度，成语共提供个人、通用、学科、职业、专业、主题等6大类36种语境算法引擎。
        </div>

        <div class="text">
          <span style="color: #666; font-size: 14px">普通会员</span
          >可使用个人类、通用类语境算法。
        </div>

        <div class="text">
          <span style="font-size: 14px">VIP会员</span
          >可叠加学科类、职业类语境算法，适合公职人员、大学生、白领金领、作文教师、申论辅导、K12父母等低频普通写作。
        </div>

        <div class="text">
          <span style="font-size: 14px">SVIP会员</span
          >可叠加专业类、主题类语境算法，适合领导干部、记者、作家、执法司法人员、律师、教授、智库专家、报社与出版社编辑、小说家、软文创作者等高频专业写作。
        </div>

        <div class="vip_btn">
          <div
            class="btn_item"
            @click="
              $router.push({
                name: 'VIPcenter',
              })
            "
          >
            <img :src="require('assets/images/newTweet/vip1.png')" alt="" />
            查看VIP/SVIP会员权益
          </div>
          <div
            class="btn_item wear"
            @click="
              $router.push({
                name: 'details',
                params: {
                  id: 1,
                },
              })
            "
          >
            <img :src="require('assets/images/newTweet/vip2.png')" alt="" />
            开通VIP/SVIP会员
          </div>
        </div>
      </div>
      <div class="centre">
        <div class="title">应用视频</div>

        <div
          v-for="item in videoList"
          :key="item"
          class="video_item"
          @click="viewVideo(item)"
        >
          <img :src="url + item.imgUrl" alt="" />

          <div class="video_title">{{ item.title }}</div>

          <div class="video_related">
            <div class="heat">
              <i class="heat_icon" />
              {{ item.hot }}
            </div>
            <div class="score">
              <i class="score_icon" />
              {{ item.star }}
            </div>
            <div class="review">
              <i class="score_icon" />
              {{ item.commentNum }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="right">
        <div class="title">图文指引</div>

        <div class="text_item">成语极速版“智能推文” 图文指引</div>
        <div class="text_item">成语Office/WPS版“智 能推文”图文指引</div>
      </div> -->
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
import commonFooter from "components/footer/commonFooter.vue";
import { getListById } from "api/videoWriting";
export default {
  components: {
    commonFooter,
  },
  data() {
    return {
      videoList: [],
      url: this.$store.state.onlineBasePath,
    };
  },
  created() {
    this.getVideoList();
  },
  methods: {
    async getVideoList() {
      const params = {
        page: 1,
        limit: 10,
        parentId: "",
        title: "智能推文",
        timeSort: "",
        hotSort: "",
        starSort: "",
        commentSort: "",
      };

      const res = await getListById(params);
      this.videoList = res.data.data;
      // this.total = res.data.total;
    },
    viewVideo(item) {
      this.$router.push("/videoWritingInfo/" + item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.newTweet {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #fbf8fb;
  .box {
    width: 1350px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .left {
      width: 1080px;
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 40px 70px;
      .title {
        margin-bottom: 40px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }
      .text {
        margin-bottom: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 24px;

        span {
          font-size: 18px;
          color: #ff6500;
          font-weight: bold;
        }
      }
      .btn_box {
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn_item {
          cursor: pointer;
          justify-content: center;
          width: 49%;
          height: 50px;
          background: #ff6900;
          border-radius: 5px;
          color: #fff;
          display: flex;
          align-items: center;
          line-height: 50px;
          font-size: 16px;

          img {
            margin-right: 10px;
            width: 32px;
            height: 32px;
          }
        }

        .fff {
          color: #ff6900;
          box-sizing: border-box;
          border: 1px solid #ff6500;
          background-color: #fff;
        }
      }

      .vip_btn {
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn_item {
          cursor: pointer;
          justify-content: center;
          width: 49%;
          height: 50px;
          background: #fff;
          border-radius: 5px;
          color: #d1753d;
          display: flex;
          align-items: center;
          line-height: 50px;
          font-size: 16px;
          border: 1px solid;

          img {
            margin-right: 10px;
            width: 32px;
            height: 32px;
          }
        }

        .wear {
          color: #fff;
          border: 0px;
          background: linear-gradient(90deg, #db814a 1%, #edc6af 100%);
        }
      }

      .bgc_img {
        margin: 0 auto;
        margin-bottom: 40px;
      }
    }
    .centre {
      width: 240px;
      box-sizing: border-box;
      background-color: #fff;

      .title {
        margin-top: 20px;
        padding-left: 12px;
        border-left: 3px solid #ff6500;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 12px;
      }

      .video_item {
        padding: 20px;
        cursor: pointer;

        &:hover {
          background-color: #f5f5f560;
        }
        img {
          width: 100%;
        }

        .video_title {
          margin: 10px 0;
          font-size: 14px;
          line-height: 1.5;
          font-weight: bold;
        }

        .video_related {
          margin-top: 10px;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          color: #666666;
          .heat {
            display: flex;
            align-items: center;
            .heat_icon {
              width: 14px;
              height: 14px;
              margin-right: 10px;
              background: url("~assets/images/info/hot.png") no-repeat;
              background-size: 100% 100%;
            }
          }

          .score {
            display: flex;
            align-items: center;
            .score_icon {
              width: 14px;
              height: 14px;
              margin-right: 10px;
              background: url("~assets/images/writing/score_yellow.png")
                no-repeat;
              background-size: 100% 100%;
            }
          }

          .review {
            display: flex;
            align-items: center;
            .score_icon {
              width: 14px;
              height: 14px;
              margin-right: 10px;
              background: url("~assets/images/writing/review.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
    .right {
      width: 240px;
      box-sizing: border-box;
      background-color: #fff;

      .title {
        margin-top: 20px;
        padding-left: 12px;
        border-left: 3px solid #ff6500;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 12px;
      }

      .text_item {
        cursor: pointer;
        font-size: 14px;
        padding: 10px 20px;
        line-height: 1.5;
        color: #333;

        &:hover {
          color: #ff6500;
        }
      }
    }
  }

  .commonFooter_box {
    margin-top: 20px;
    width: 100%;
  }
}
</style>
